import React from 'react';
import { Box } from '@/components/Box/Box';
import {
  COLOR,
  PADDING,
  MARGIN
} from '@latitude/core/utils/constants';
import './_faqs.scss';

        let items=[
            {
              id: 'lat-app-need-help',
              title: 'Need help getting started on the Latitude App?',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/360013720418-Need-help-getting-started-on-the-Latitude-App-"
                    target="_blank"
                  >
                    Need help getting started on the Latitude App?
                  </a>
                </div>
              )
            },
            {
              id: 'mobole-tb-faq',
              title: 'Mobile App troubleshooting FAQs',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/360018266637-Mobile-App-troubleshooting-FAQs"
                    target="_blank"
                  >
                    Mobile App troubleshooting FAQs
                  </a>
                </div>
              )
            },
            {
              id: 'reset-lat-login-details',
              title: 'Reset Latitude ID Login Details',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/360007143818-Reset-Latitude-ID-Login-Details"
                    target="_blank"
                  >
                    Reset Latitude ID Login Details
                  </a>
                </div>
              )
            },
            {
              id: 'fueture-acc-faq',
              title: 'Mobile App features and accounts FAQs',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/360018392838-Mobile-App-features-and-accounts-FAQs"
                    target="_blank"
                  >
                    Mobile App features and accounts FAQs
                  </a>
                </div>
              )
            },
            {
              id: 'pay-acc-app',
              title: 'Pay My Account - Mobile App"',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/5104231713297-Pay-My-Account-Mobile-App"
                    target="_blank"
                  >
                    Pay My Account - Mobile App
                  </a>
                </div>
              )
            },
            {
              id: 'my-plan-app',
              title: 'Pay My Plan - Mobile App (Payment Allocation)',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/5776792291857-Pay-My-Plan-Mobile-App"
                    target="_blank"
                  >
                    Pay My Plan - Mobile App (Payment Allocation)
                  </a>
                </div>
              )
            },
            {
              id: 'security-faq',
              title: 'Security and safety FAQs',
              content: (
                <div>
                  <a
                    href="https://latitudefs.zendesk.com/hc/en-au/articles/360018392318-Security-and-safety-FAQs"
                    target="_blank"
                  >
                    Security and safety FAQs
                  </a>
                </div>
              )
            }
          ]

function FaqsSection() {
  
  return (
    <>
   <Box className='mobile-faqs'>
      <h2 id="faq">FAQs</h2>
      <div>
        {items.map((item,index)=>{
         return <Box className='faq-tile'
         margin={MARGIN.M8}
         backgroundColor={COLOR.GREY5}
         padding={PADDING.P16}
         isResponsive
         key={index}>{item.content} </Box>
     })}
      </div>
      </Box>
    </>
  );
}

export default FaqsSection;